.option-text-left,
.option-text-right {
  transition: all 0.2s;
  pointer-events: all;
  margin-bottom: 20px;
  font-weight: 500;
}

.option-text-left:hover,
.option-text-right:hover {
  cursor: pointer;
  font-size: 120%;
}
